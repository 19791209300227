import cx from "classnames";

import css from "./Row.module.scss";

type Props = {
  children: React.ReactNode;
  className?: string;
  gap?: string;
  justify?:
    | "center"
    | "start"
    | "space-between"
    | "space-around"
    | "space-evenly"
    | "normal"
    | "flex-start"
    | "flex-end"
    | "left"
    | "right"
    | "stretch";
  style?: React.CSSProperties;
  wrap?: "nowrap" | "wrap" | "wrap-reverse";
};

const Row: React.FC<Props> = ({
  children,
  className,
  gap = "1rem",
  justify = "space-between",
  style,
  wrap = "nowrap",
}) => {
  const styles = {
    justifyContent: justify,
    gap,
    flexWrap: wrap,
    ...style,
  };

  return (
    <>
      <div className={cx(css.row, className)} style={styles}>
        {children}
      </div>
    </>
  );
};

export default Row;
