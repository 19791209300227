"use client";

import Image from "next/image";
import { useRouter } from "next/navigation";

import { useEffect, useRef, useState } from "react";

import { Password } from "primereact/password";
import { Button } from "primereact/button";

import Row from "@/src/components/ui/row/Row";
import { useBusyBar } from "@/src/components/ui/busy-bar";
import { doLogger } from "@/src/lib/logger";

import { knockAction } from "./bouncer-actions";

import css from "./Bouncer.module.scss";

const log = doLogger("Bouncer");

const Bouncer = () => {
  const [value, setValue] = useState("");

  const router = useRouter();
  const { busyBar, setBusy } = useBusyBar();

  const onSubmit = async (e: any) => {
    e.preventDefault();

    try {
      setBusy(true);
      const result = await knockAction(value);
      log.debug("result", result);

      if (result.status === "OK") {
        router.push("/dashboard");
      } else {
        throw new Error(result.message);
      }
    } catch (e: any) {
      console.error("knock failed", e);
    } finally {
      setBusy(false);
      setValue("");
    }
  };

  const focusRef = useRef<any>();
  useEffect(() => {
    focusRef.current.focus();
  }, []);

  return (
    <div className={css.bouncer}>
      <header>
        <h3>What&apos;s the secret knock?</h3>
      </header>

      <Image src="/bouncer.png" width={300} height={518} alt="Yeah?" priority />

      <form onSubmit={onSubmit}>
        <Row>
          <Password
            ref={focusRef}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            feedback={false}
            autoComplete="on"
          />

          <Button type="submit" label="Knock" />
        </Row>
        <div>{busyBar()}</div>
      </form>
    </div>
  );
};

export default Bouncer;
