import { DateTime } from "luxon";

export type LoggerOptions = {
  disabled?: boolean;
  timestamp?: boolean;
  colored?: boolean;
};

type Level = "log" | "debug" | "dir" | "info" | "error" | "warn";

const defaultOptions: LoggerOptions = {
  disabled: false,
  timestamp: true,
  colored: true,
};

const action: Record<Level, any> = {
  log: console.log,
  debug: console.debug,
  dir: console.dir,
  info: console.info,
  error: console.error,
  warn: console.warn,
};

export function doLogger(name: string, options: LoggerOptions = {}) {
  const { disabled, timestamp, colored } = {
    ...defaultOptions,
    ...options,
  };

  function log(level: Level, ...args: any[]) {
    if (disabled) return;

    const logger = action[level || "debug"];

    const now = timestamp
      ? `${DateTime.fromISO(new Date().toISOString()).toLocaleString(
          DateTime.DATETIME_FULL_WITH_SECONDS
        )} `
      : "";

    if (colored) {
      logger(
        `%c[[${level}]] %c${now}%c${name}:`,
        "color: green",
        "color: orange",
        "color: yellow",
        ...args
      );
    } else {
      logger(`[[${level}]] ${now}${name}`, ...args);
    }
  }

  return {
    debug: (...args: any[]) => {
      log("debug", ...args);
    },
    dir: (...args: any[]) => {
      log("dir", ...args);
    },
    error: (...args: any[]) => {
      log("error", ...args);
    },
    info: (...args: any[]) => {
      log("info", ...args);
    },
    log: (...args: any[]) => {
      log("log", ...args);
    },
    warn: (...args: any[]) => {
      log("warn", ...args);
    },
  };
}
