import { useState } from "react";
import BusyBar from ".";

export function useBusyBar(show: boolean = false) {
  const [busy, setBusy] = useState(show);

  const busyBar = () => {
    return <BusyBar show={busy} />;
  };

  return { setBusy, busyBar };
}
