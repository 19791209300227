import { ProgressSpinner } from "primereact/progressspinner";

import css from "./BusyBar.module.css";

interface Props {
  show: boolean;
}

const BusyBar = ({ show }: Props) => {
  return (
    <>
      {show && (
        <div className={css.busyBar}>
          <ProgressSpinner />
        </div>
      )}
    </>
  );
};

export default BusyBar;
